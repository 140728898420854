<template>
  <div class="hello"  :class="{'shake' : animated}">
    <h1>{{ msg }}</h1>

    <div class="letter-grid">
      <letter
        v-for="(lett, index) in [...letters]"
        :key="index"
        :letter="lett"
        :idx="index"
        v-bind:isActive="letterStates[index]"
      ></letter>
    </div>
    <div class="current-word-row" :class="{'shake' : animated}">
      <current-word :word="getCurrentWord()" />
      <h2 class="go-button" @click="submitWord()">&rarr;</h2>
    </div>
    <v-divider></v-divider>

    <div class="user-words">
      Score {{ userWordsCount }} / {{ totalWordsCount }}
      <br>
      <v-chip
        v-for="(word, index) in [...userWords]"
        :key="index"
        class="ma-2"
        label
      >
        {{ word }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import Letter from "./Letter.vue";
import CurrentWord from "./CurrentWord.vue";
import store from "@/store"; // short for @/store/index

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Letter,
    CurrentWord,
  },
  computed: {
    letterStates() {
      return this.$store.getters.gridLetterStates;
    },
    userWords() {
      return this.$store.getters.userWords;
    },
    userWordsCount() {
      return this.$store.getters.userWordsCount;
    },
    totalWordsCount() {
      return this.$store.getters.totalWordsCount;
    },
  },
  data() {
    return {
      letters: this.$store.getters.gridLetters,
      animated: false
    };
  },


  methods: {
    getCurrentWord() {
      return this.$store.getters.currentWord;
    },
    _keyListener(e) {
      // console.log("KEY: " + e.key);

      var upper = e.key;
      upper = upper.toUpperCase();
      // console.log("upper = " + upper);

      if (this.letters.includes(upper)) {
        var letters = this.$store.getters.gridLetters;
        var letterStates = this.$store.getters.gridLetterStates;
        // var found = false;

        // first check if centre letter
        var match = false;
        if (letters[4] == upper && letterStates[4] == 1) {
          store.dispatch("setLetterStateUsed", {
            index: 4,
          });
          store.dispatch("addLetterToCurrentWord", {
            letter: upper,
          });
          match = true;
        }
        
        for (var i = 0; i < letters.length; i++) {
          if (letters[i] == upper && letterStates[i] == 1 && match == false) {
            store.dispatch("setLetterStateUsed", {
              index: i,
            });
            store.dispatch("addLetterToCurrentWord", {
              letter: upper,
            });
            break;
          }
        }
      }

      if (e.key == "Backspace") {
        if (this.getCurrentWord().length > 0) {
          // console.log("DEL");
          store.dispatch("removeLastLetter", {});
        }
      }
      if (e.key == "Enter") {
        var origCount = this.userWordsCount;
        store.dispatch("submitWord", {});
        if (this.userWordsCount == origCount) {

          const self = this
          self.animated = true
          setTimeout(() => {
            self.animated = false
          }, 1000);
        }
      }

      e.preventDefault();
    },

    submitWord() {
      // console.log("clicck");
      // if (this.getCurrentWord().length > 3) {
      //   console.log("SUBMIT WORD");
      var origCount = this.userWordsCount;
      store.dispatch("submitWord", {});
      if (this.userWordsCount == origCount) {

        const self = this
        self.animated = true
        setTimeout(() => {
          self.animated = false
        }, 1000);
      }
      // }
    },

},
  mounted() {
    store.dispatch("decodeValidWords", {});
    window.addEventListener("keydown", this._keyListener);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this._keyListener);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.letter-grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 600px;
  margin: 0 auto;
}
.letter-item {
  justify-self: stretch;
}
.current-word-row {
  max-width: 600px;
  margin-left: auto !important;
  margin-right: auto !important;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.go-button {
  font-size: 4em;
  width: 20%;
  display: inline-block;
  cursor: pointer;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
