import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gridLetters: ["T", "E", "S", "N", "G", "R", "A", "D", "E"],

    gridLetterStates: [1, 1, 1, 1, 1, 1, 1, 1, 1],

    currentWord: "",

    validWords: [
      "NTRQ", "NTRF", "QENT", "RQTR", "RTNQ", "RETF", "TNQF", "TNEF", "TNGR", "TRNE", "TRAR", "TRAG", "TRGF", "TANG", "TENQ", "ANTF", "ENTR", "ENTF", "ENAT", "FNTR", "FNAT", "FANT", "FGNT", "GNTF", "NTRAG", "NTERR", "NATRE", "NATFG", "QENTF", "QERTF", "RNTRE", "RQTRF", "RTERG", "TNGRF", "TRNEF", "TRARF", "TRARG", "TRAER", "TRAGF", "TANGF", "TENQF", "TENQR", "TENAQ", "TENAG", "TENGR", "TERNG", "TERRQ", "TERRA", "TERRG", "ENTRF", "ENTRQ", "ENATR", "FNTRE", "FRQTR", "FRETR", "FGNTR", "GNATF", "NTRAGF", "NTERRF", "NTERRQ", "NATREF", "NETRAG", "QNATRE", "RTERGF", "RAENTR", "TNAQRE", "TNEQRA", "TNEARG", "TRNERQ", "TRAQRE", "TRAREN", "TRARGF", "TRAERF", "TENQRF", "TENAQF", "TENAGF", "TENGRQ", "TENGRF", "TERNFR", "TERNGF", "TERRQF", "TERRAF", "TERRGF", "ARTNGR", "ENATRQ", "ENATRF", "ERTRAG", "FGNTRQ", "NATRERQ", "QNATREF", "QRENATR", "QENTARG", "RAENTRQ", "RAENTRF", "TNAQREF", "TNEQRAF", "TNEARGF", "TRAQREF", "TENAQRR", "TENAGRQ", "TENAGRR", "TERNFRQ", "TERANQR", "ARTNGRQ", "ARTNGRF", "ERNTRAG", "ERTRAGF", "FGENATR", "QRENATRF", "QENTARGF", "RFGENATR", "TENAQRFG", "TENAQRRF", "TENAGRRF", "TERANQRF", "ERNTRAGF", "FRETRNAG", "RFGENATRQ"     ]
    ,
    userWords: [],
  },

  getters: {
    gridLetters: (state) => {
      return state.gridLetters;
    },
    gridLetterStates: (state) => {
      return state.gridLetterStates;
    },
    specialLetter: (state) => {
      return state.gridLetters[4];
    },
    currentWord: (state) => {
      return state.currentWord;
    },
    currentWordLength: (state) => {
      return state.currentWord.length;
    },
    validWords: (state) => {
      return state.validWords;
    },
    userWords: (state) => {
      return state.userWords;
    },
    userWordsCount: (state) => {
      return state.userWords.length;
    },
    totalWordsCount: (state) => {
      return state.validWords.length;
    },
  },

  mutations: {
    changeCurrentWord(state, payload) {
      // console.log("Store: updating currentword to: " + payload);
      state.currentWord = payload;
    },

    updateLetterStates(state, payload) {
      // console.log("Store: updating letterstates to: " + payload);
      state.gridLetterStates = payload;
    },

    updateUserWords(state, payload) {
      // console.log("Store: updating userWords " + payload);
      state.userWords = payload;
    },

    updateValidWords(state, payload) {
      state.validWords = payload;
    },

    updateGridLetters(state, payload) {
      state.gridLetters = payload;
    }
  },

  actions: {
    decodeValidWords(context) {
      const rot13 = str => str.split('')
        .map(char => String.fromCharCode(char.charCodeAt(0) + (char.toLowerCase() < 'n' ? 13 : -13)))
        .join('');
      
      var validWords = context.getters.validWords;
      var decoded = validWords.map(str => rot13(str));
      context.commit("updateValidWords", decoded);
    },

    addLetterToCurrentWord(context, payload) {
      var word = context.getters.currentWord + payload.letter;
      context.commit("changeCurrentWord", word);
    },

    setLetterStateUsed(context, payload) {
      var letterStates = context.getters.gridLetterStates;
      letterStates[payload.index] = 0;
      context.commit("updateLetterStates", letterStates);
    },

    clearCurrentWord(context) {
      context.commit("changeCurrentWord", '');
      context.commit("updateLetterStates", [1, 1, 1, 1, 1, 1, 1, 1, 1]);

    },

    removeLastLetter(context) {
      var word = context.getters.currentWord;
      var lastChar = word.slice(-1);
      word = word.slice(0, -1);
      context.commit("changeCurrentWord", word);

      var letters = context.getters.gridLetters;
      var letterStates = context.getters.gridLetterStates;
      var i = -1;
      while ((i = letters.indexOf(lastChar, i + 1)) != -1) {
        //index.push(i);
        if (letterStates[i] == 0) {
          letterStates[i] = 1;
          context.commit("updateLetterStates", letterStates);
          break;
        }
      }
    },

    submitWord(context) {
      var word = context.getters.currentWord;
      var userWords = context.getters.userWords;
      var validWords = context.getters.validWords;
      if (validWords.indexOf(word) != -1 && userWords.indexOf(word) == -1) {
        userWords.push(word);
        context.commit("updateUserWords", userWords);
        context.commit("updateLetterStates", [1, 1, 1, 1, 1, 1, 1, 1, 1]);
        context.commit("changeCurrentWord", "");
      } else {
        context.commit("updateLetterStates", [1, 1, 1, 1, 1, 1, 1, 1, 1]);
        context.commit("changeCurrentWord", "");

      }
    },

    shuffleLetters(context) {
      context.commit("updateLetterStates", [1, 1, 1, 1, 1, 1, 1, 1, 1]);
      context.commit("changeCurrentWord", "");
      var gridLetters = context.getters.gridLetters;

      for (let i = gridLetters.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          if (i != 4 && j != 4) {
            const temp = gridLetters[i];
            gridLetters[i] = gridLetters[j];
            gridLetters[j] = temp;
          }
      }
      for (let i = gridLetters.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        if (i != 4 && j != 4) {
          const temp = gridLetters[i];
          gridLetters[i] = gridLetters[j];
          gridLetters[j] = temp;
        }
    }

      context.commit("updateGridLetters", gridLetters);
    }
  },
});
