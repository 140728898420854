import Vue from "vue";

import store from "@/store"; // short for @/store/index
import App from "./App.vue";
import vuetify from "./plugins/vuetify.js"; // path to vuetify export
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import VueMeta from 'vue-meta';


Vue.config.productionTip = false;

new Vue({
  vuetify,
  LottieAnimation,
  VueMeta,
  store,
  render: (h) => h(App),
}).$mount("#app");
