<template>
  <div class="word-container">
    <h2>{{ word }}</h2>
    <div v-if="this.hasLetters" class="delete" @click="clearWord()">X</div>
  </div>
</template>

<script>
import store from '@/store'; // short for @/store/index

export default {
  name: "CurrentWord",
  props: {
    word: String,
  },
  data() {
    return {};
  },
  computed: {
     hasLetters() {
       return (store.getters.currentWordLength > 0);
     } 
  },
    methods: {
      clearWord() {
        store.dispatch("clearCurrentWord", {});
      }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.word-container {
  width: 80%;
  display: inline-block;
}
h2 {
  font-size: 3.5em;
  display: inline-block;
}
.delete {
  color: #f00;
  display: inline-block;
  width: 25px;
  /* position: absolute; */
  float: right;
  margin-top: 1em;
  font-weight: 800;
  border: 1px solid red;
  cursor: pointer;
}

</style>
