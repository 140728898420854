<template>
  <v-app>
    <div id="app">
      <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
      <div         @click="shuffleLetters()">

      <lottie-animation
        path="./static/brainy.json"
        class="lottie-class"
        :width="160"
        :height="160"
        :loop="false"
      />
      </div>

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="help-button"
          >?</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Beta version, under development.</v-toolbar>
            <v-card-text>
              <div class="text-p pa-12">
                Words must be 4 letters or more.<br>
                Words must use the centre letter.<br>
                Click the brain to shuffle letters.
                <br><br>
                
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>


      <HelloWorld msg="" />
    </div>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import store from '@/store'; // short for @/store/index


export default {
  name: "App",
  components: {
    HelloWorld,
    LottieAnimation,
  },
  metaInfo: {
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  methods: {
    shuffleLetters() {
      store.dispatch("shuffleLetters", {});
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  max-width: 660px;
  min-width: 480px;
  margin: 0 auto;
}
.help-button {
  margin-bottom: 12px !important;
  position: absolute !important;
  top: 10px;
  right: 20px;
}
.lottie-class {
  cursor: pointer;
}

</style>
