<template>
  <v-card
    class="pa-2 py-6"
    v-bind:class="{ used: isActive == 0, req: idx == 5 }"
    outlined
    tile
    @click="useLetter()"
  >
    <h2>{{ letter }}</h2>
  </v-card>
</template>

<script>
import store from "@/store"; // short for @/store/index

export default {
  name: "Letter",
  props: {
    letter: String,
    idx: Number,
    isActive: Number,
  },
  data() {
    return {};
  },
  methods: {
    useLetter() {
      if (store.getters.gridLetterStates[this.idx] == 0) {
        return;
      }
      store.dispatch("addLetterToCurrentWord", {
        letter: this.letter,
      });
      store.dispatch("setLetterStateUsed", {
        index: this.idx,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.used {
  opacity: 20%;
}
.req {
  border: 1px solid red;
}
</style>
<style scoped>
h2 {
  font-size: 4em;
}
</style>
